import { PrivacyType, ThanksResponse } from "../model/ThanksModel";
import { toDateAndTimeString } from "../utils/DateUtils";
import '../style/ThanksCard.css';
import { AI_PRO_EMAIL_ERROR, AI_PRO_EMAIL_SENT, AI_PRO_SEND_EMAIL_TOOLTIP, LOCK_ICON_TOOLTIP, Language, SEE_MORE, TranslationService } from "../services/TranslationService";
import { AuthService } from "../services/AuthService";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { resolveImage } from "../utils/UserUtils";
import { privacyTypeOf } from "../utils/ThanksUtils";
import deleteIcon from "../assets/images/delete_icon.png";
import lockIcon from "../assets/images/lock.png";
import sendIcon from '../assets/images/send_white.png';
import { Tooltip } from "react-tooltip";
import { isMobile } from "react-device-detect";
import { ThanksService } from "../services/ThanksService";
import { enqueueSnackbar } from "notistack";
import { AxiosError } from "axios";
import { ErrorResponse } from "../model/ErrorResponse";
import { UserService } from "../services/UserService";
import { Loader } from "./Loader";

export type ThanksCardProps = {
    thanks: ThanksResponse;
    onPrivacyTypeChange(thanksId: string, privacyType: PrivacyType): void;
    onUserImageClick(userId: string): void;
    language: Language | undefined;
    onClickedDelete(thanksId: string): void;
    userImageUrl: string | null;
    pageUserId: string | null | undefined;
    timestamp: number;
    userCanSendEmail: boolean;
    sentEmail: () => void;
}

const ThanksCard: React.FC<ThanksCardProps> = (props: ThanksCardProps) => {

    const MAX_INITIAL_TEXT_SIZE = 150;

    const translationService: TranslationService = new TranslationService();
    const authService: AuthService = new AuthService();
    const thanksService: ThanksService = new ThanksService();
    const userService: UserService = new UserService();
    // User ID of page we are visiting
    const pageUserId: string | undefined | null = props.pageUserId;
    // Our own User ID
    const thisUserId: string = authService.readUserIdFromToken()!;
    const [cardStyle, setCardStyle] = useState<string>('');
    const [privacyType, setPrivacyType] = useState<PrivacyType>(props.thanks.privacyType);
    const [profilePicUrl, setProfilePicUrl] = useState<string>(resolveImage(props.thanks.giver.profilePictureUrl));
    const [text, setText] = useState<string>();
    const [isTruncated, setIsTruncated] = useState<boolean>();
    const [canSendEmail, setCanSendEmail] = useState<boolean>(false);
    const [sendingEmail, setSendingEmail] = useState<boolean>(false);
    const [textMessage, setTextMessage] = useState<string>();
    
    useEffect(() => {
        const textThanks: string = props.thanks.text;
        let initialText: string;

        if (textThanks.length > MAX_INITIAL_TEXT_SIZE) {
            initialText = textThanks.substring(0, MAX_INITIAL_TEXT_SIZE);
        } else {
            initialText = textThanks;
        }

        setIsTruncated(textThanks.length > MAX_INITIAL_TEXT_SIZE);
        setText(initialText);
        setCanSendEmail(resolveCanSendEmail(props.thanks));
        resolveIntroCallBack();
    }, []);

    useEffect(() => {
        setCardStyle(resolveCardStyle());
    }, [props.thanks.privacyType])

    useEffect(() => {
        if (isUserPage() && props.timestamp !== 0) {
            const newImageUrl: string = `${props.userImageUrl}?v=${props.timestamp}`;
            setProfilePicUrl(newImageUrl);
        }
    }, [props.timestamp]);

    useEffect(() => {
        setCanSendEmail(resolveCanSendEmail(props.thanks));
    }, [sendingEmail]);

    const resolveIntroCallBack = useCallback(() => {
        const resolveIntro = () => {
            const thanks: ThanksResponse = props.thanks;
            let message: string = "";
    
            if (thisUserId === thanks.giver.id && thanks.giver.id === thanks.receiver.id) {
                // Thanks in own page
                message = `${translationService.getIntro()}`;
            } else if (thanks.receiver.id === thanks.giver.id) {
                // The other person's thanks on her/his own page
                message = `${thanks.giver.name} ${translationService.getOthersIntro()}`
            } else {
                // Another person thanking in another one's page
                message = `${thanks.giver.name} ${translationService.getOthersIntro()} ${thanks.receiver.name}`
            }
    
            setTextMessage(message);
        }
        resolveIntro();
    }, []);

    const handlePrivacyTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const updatedPrivacyType: PrivacyType = privacyTypeOf(event.target.value);
        setPrivacyType(updatedPrivacyType);

        props.onPrivacyTypeChange(props.thanks.id, updatedPrivacyType);
    }

    const handleUserImageClick = () => {
        if (!isUserPage()) {
            props.onUserImageClick(props.thanks.giver.id);
        }
    }

    const isUserPage = (): boolean => {
        return pageUserId !== undefined && pageUserId !== null && 
            authService.readUserIdFromToken() === props.thanks.giver.id && pageUserId === props.thanks.giver.id
    }

    const resolveImageStyle = (): string => {
        let style = `circle-thanks ${isMobile ? "thanks-card-size-mobile" : "thanks-card-size"}`;

        if (!isUserPage()) {
            style += " clickable"
        }

        return style;
    }

    const canChangePrivacy = (): boolean => {
        const thanks: ThanksResponse = props.thanks;

        return thisUserId === thanks.giver.id || thisUserId === thanks.receiver.id;
    }

    const resolveCardColour = (): string => {
        return props.thanks.privacyType === PrivacyType.PRIVATE ? "private-thanks-color" : "public-thanks-color";
    }

    const resolveCardStyle = (): string => {
        return `card-thanks ${resolveCardColour()}`;
    }

    const isUserRelated = (): boolean => {
        return thisUserId === props.thanks.giver.id || thisUserId === props.thanks.receiver.id;
    }

    const handleClickedDelete = () => {
        props.onClickedDelete(props.thanks.id);
    }

    const resolvePrivacyOptions = (): PrivacyType[] => {
        if (authService.readUserIdFromToken() !== props.thanks.giver.id) {
            return [PrivacyType.PRIVATE];
        }

        return Object.values(PrivacyType).filter(type => typeof type === 'string');
    }

    const handleSeeMoreClick = () => {
        setText(props.thanks.text);
        setIsTruncated(false);
    }

    const resolveTextClasses = (): string => {
        return `card-element text ${isMobile ? "mobile-thanks-text" : ""}`;
    }

    const resolveCanSendEmail = (thanks: ThanksResponse): boolean => {
        return thanks.giver.id === userService.getUserId()! &&
          !sendingEmail && thanks.giver.id !== thanks.receiver.id && !thanks.sentEmail;
      }

    const handleSendEmailClick = async() => {
        setSendingEmail(true);
        await thanksService.sendPremiumEmail(props.thanks.id)
        .then(() => {
          enqueueSnackbar(translationService.getFor(AI_PRO_EMAIL_SENT), { variant: 'success' })
          setCanSendEmail(false);
          props.sentEmail();
        })
        .catch((e: AxiosError) => {
          const error: ErrorResponse = e.response?.data as ErrorResponse;
          enqueueSnackbar(`${translationService.getFor(AI_PRO_EMAIL_ERROR)}: ${error.detail}`, { variant: 'error' });
        })
        .finally(() => {
            setSendingEmail(false);
        });
      }

    return (
        <div className="thanks-card-container">
            <div className={cardStyle}>
                <div className="giver-thanks-card">
                    <div className={resolveImageStyle()}>
                        <img src={profilePicUrl} onClick={handleUserImageClick} />
                    </div>
                </div>
                <div className={resolveTextClasses()}>
                    <div className="card-element">
                        {textMessage}:
                    </div>
                    <div className="card-element text">
                        <b>{text}</b> {isTruncated && "..." && 
                        (<label className="text-label" onClick={handleSeeMoreClick}>{translationService.getFor(SEE_MORE)}</label>)}
                    </div>
                    <div className="card-element date">
                        {toDateAndTimeString(props.thanks.date)}
                        {canChangePrivacy() && <div className="privacy-box">
                            <select value={privacyType} className="select-thanks-privacy" name='privacyType' onChange={handlePrivacyTypeChange}>
                                {resolvePrivacyOptions().map(type => (
                                    <option key={type} value={type}>{translationService.getFor(type)}</option>
                                ))}
                            </select>
                        </div>
                        }
                    </div>
                </div>
                { isUserRelated() && <div className="delete-container">
                    <img src={deleteIcon} className="delete-icon" onClick={handleClickedDelete}/>
                </div>
                }
                {
                    canSendEmail && !sendingEmail && props.userCanSendEmail &&
                    (
                        <div>
                            <img className="send-email" src={sendIcon} onClick={handleSendEmailClick} />
                            <Tooltip id="send-lock" anchorSelect=".send-email" place="top" className="tooltip">
                                {translationService.getFor(AI_PRO_SEND_EMAIL_TOOLTIP)}
                            </Tooltip>
                        </div>
                    )
                }
                <div className="lock">
                    <img src={lockIcon} className="lock-img" data-tooltip-id="tooltip-lock" />
                    <Tooltip id="tooltip-lock" anchorSelect=".lock-img" place="top" className="tooltip">
                            {translationService.getFor(LOCK_ICON_TOOLTIP)}
                    </Tooltip>
                </div>
                <div>
                    {sendingEmail && <Loader size="small"/>}
                </div>
            </div>
        </div>
    );

}

export default React.memo(ThanksCard);